async function getDetailsForPlaceId(placeId) {
  return new Promise((resolve, reject) => {
    const placesService = new window["google"].maps.places.PlacesService(
      document.createElement("div")
    )
    const request = {
      placeId,
      fields: ["address_components"],
    }
    placesService.getDetails(request, (result, status) => {
      resolve(result)
    })
  })
}

export default getDetailsForPlaceId
