import React from "react"
import { Heading } from "grommet"
import styled from "styled-components"

const StyledHeading = styled(Heading)`
  text-transform: ${p => p.theme.heading.font.transform || "none"};
  text-rendering: optimizeLegibility;
  @media (max-width: 414px) {
    font-size: 22px;
    line-height: 1.6rem;
  }
  @media (max-width: 320px) {
    font-size: 20px;
    line-height: 1.6rem;
  }
`

const BDHeading = ({ children, ...rest }) => (
  <StyledHeading responsive={true} {...rest}>
    {children}
  </StyledHeading>
)

export default BDHeading
