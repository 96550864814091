import React, { useEffect } from "react"
import { Field } from "formik"
import { Box, Text, TextArea, CheckBoxGroup } from "grommet"

import { omit } from "lodash/fp"

import store from "store"

import FieldInputWithErrors from "./FieldInputWithErrors"
import InputSelect from "./InputSelect"
import InputFileUpload from "./InputFileUpload"

const industryOptions = [
  "Architect",
  "General Contractor",
  "Home Stager",
  "Interior Designer",
  "Photographer",
  "Professional Painter",
  "Property Developer",
  "Property Manager",
  "Realtor",
  "Student",
  "Other",
]

const budgetOptions = [
  "<$2,000",
  "$2,000 to $10,000",
  "$10,000 to $25,000",
  "$25,000 to $50,000",
  "$50,000 to $100,000",
  "$100,000+",
]

const focusOptions = [
  "Single family residential",
  "Multi-family residential",
  "Commercial",
  "Hospitality",
  "Other",
]

const FormSignupStepDetails = ({ formikProps, referral_fetched }) => {
  useEffect(() => {
    if (formikProps.values) {
      store.set(
        "form_values",
        omit(
          ["documents", "referred_by", "referred_by_name", "referred_by_email"],
          formikProps.values
        )
      )
    }
  }, [formikProps.values])
  return (
    <Box gap="small">
      <Field
        component={FieldInputWithErrors}
        name="social_handle"
        placeholder="Social Media Handle (Instagram/Linkedin)"
      />
      <Field
        component={FieldInputWithErrors}
        inputComponent={InputSelect}
        options={industryOptions}
        name="industry_type"
        placeholder="Industry Type"
      />
      <Box margin={{ vertical: "small" }} gap="small">
        <Field
          component={FieldInputWithErrors}
          inputComponent={CheckBoxGroup}
          options={focusOptions}
          name="primary_focus"
          label="Your Primary Focus (check all that apply)"
        />
        {formikProps.values.primary_focus.indexOf("Other") > -1 && (
          <Field
            component={FieldInputWithErrors}
            name="other_primary_focus"
            placeholder="Please specify"
            autoFocus={true}
          />
        )}
      </Box>
      <Field
        component={FieldInputWithErrors}
        inputComponent={InputSelect}
        options={budgetOptions}
        name="annual_paint_budget"
        placeholder="What is your approximate annual paint budget?"
      />

      <Box direction="row-responsive" gap="small" justify="stretch">
        <Field
          component={FieldInputWithErrors}
          name="referred_by_name"
          placeholder="Referred by Name"
          floatingLabel={referral_fetched && "Referrer Name"}
          autocapitalize={true}
          readOnly={referral_fetched}
          inputColor={referral_fetched ? "#999" : "black"}
        />
        <Field
          component={FieldInputWithErrors}
          name="referred_by_email"
          placeholder="Referred by Email Address"
          floatingLabel={referral_fetched && "Referrer Email"}
          readOnly={referral_fetched}
          inputColor={referral_fetched ? "#999" : "black"}
        />
      </Box>

      <Field
        component={FieldInputWithErrors}
        inputComponent={TextArea}
        name="company_description"
        size="medium"
        style={{ height: "160px" }}
        placeholder="What else should we know about you and your work?"
      />

      <Field type="hidden" name="industry_application_id" />

      <Field
        component={FieldInputWithErrors}
        inputComponent={InputFileUpload}
        path="documents"
        name="documents"
        prompt="Drop files here or %{browse} for them."
        details={
          <Text style={{ lineHeight: "1.4em" }}>
            Upload proof of your trade status:{" "}
            <ul style={{ marginBottom: 0 }}>
              <li>
                Business card indicating the applicant’s name and trade
                profession
              </li>
              <li>
                Business license or resale certificate showing proof of a
                design-based business
              </li>
              <li>Valid Professional Trade Association membership card</li>
            </ul>
          </Text>
        }
      />
    </Box>
  )
}

export default FormSignupStepDetails
