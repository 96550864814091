import React, { useEffect, useState } from "react"
import { TextInput } from "grommet"
import useDebounce from "../lib/useDebounce"

const isClient = typeof window !== "undefined"

const InputAddressAutocomplete = props => {
  const {
    field: { value },
    onSelect,
    ...rest
  } = props

  const [predictions, setPredictions] = useState([])
  const [autocompleteService, setAutocompleteService] = useState(null)
  const debouncedSearchTerm = useDebounce(value, 200)

  useEffect(() => {
    if (!window.google) {
      const script = document.createElement("script")
      script.type = "text/javascript"
      script.src = `https://maps.google.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_API_KEY}&libraries=places`
      const headScript = document.getElementsByTagName("script")[0]
      headScript.parentNode.insertBefore(script, headScript)
      script.addEventListener("load", () => {
        setAutocompleteService(
          new window.google.maps.places.AutocompleteService()
        )
      })
    } else {
      setAutocompleteService(
        new window.google.maps.places.AutocompleteService()
      )
    }
  }, [])

  useEffect(() => {
    if (debouncedSearchTerm && isClient && autocompleteService) {
      autocompleteService.getPlacePredictions(
        {
          input: debouncedSearchTerm,
        },
        (data, status) => {
          setPredictions(data || [])
        }
      )
    } else {
      setPredictions([])
    }
  }, [debouncedSearchTerm, autocompleteService])

  return (
    <TextInput
      value={value}
      suggestions={predictions.map(x => ({
        label: x.description,
        value: x.place_id,
      }))}
      onSelect={e => onSelect(e.suggestion.value)}
      {...rest}
    />
  )
}

export default InputAddressAutocomplete
