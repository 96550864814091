import React, { useEffect } from "react"
import store from "store"
import { Field } from "formik"
import {
  Box,
  Text,
  MaskedInput,
  RadioButtonGroup,
  Paragraph,
  Anchor,
} from "grommet"

import usStates from "../lib/usStates"
import getDetailsForPlaceId from "../lib/getDetailsForPlaceId"
import parseGoogleAddressComponents from "../lib/parseGoogleAddressComponents"

import InputSelect from "./InputSelect"
import InputAddressAutocomplete from "./InputAddressAutocomplete"
import FieldInputWithErrors from "./FieldInputWithErrors"

const FormSignupStepBasics = ({ formikProps }) => {
  const handleGoogleAddress = async placeId => {
    const details = await getDetailsForPlaceId(placeId)
    const fields = parseGoogleAddressComponents(
      details.address_components,
      "business_"
    )
    formikProps.setValues(Object.assign({}, formikProps.values, fields))
  }

  useEffect(() => {
    if (formikProps.values) {
      store.set("form_values", formikProps.values)
    }
  }, [formikProps.values])

  return (
    <Box gap="small">
      <Box direction="row-responsive" gap="small" justify="stretch">
        <Field
          component={FieldInputWithErrors}
          name="first_name"
          placeholder="First Name"
          focusIndicator={false}
          autocapitalize={true}
        />
        <Field
          component={FieldInputWithErrors}
          name="last_name"
          placeholder="Last Name"
          focusIndicator={false}
          autocapitalize={true}
        />
      </Box>

      <Box direction="row-responsive" gap="small" justify="stretch">
        <Field
          component={FieldInputWithErrors}
          name="email"
          placeholder="Work Email"
        />
        <Field
          component={FieldInputWithErrors}
          inputComponent={MaskedInput}
          mask={[
            { fixed: "(" },
            {
              length: 3,
              regexp: /^[0-9]{1,3}$/,
              placeholder: "xxx",
            },
            { fixed: ")" },
            { fixed: " " },
            {
              length: 3,
              regexp: /^[0-9]{1,3}$/,
              placeholder: "xxx",
            },
            { fixed: "-" },
            {
              length: 4,
              regexp: /^[0-9]{1,4}$/,
              placeholder: "xxxx",
            },
          ]}
          name="phone"
          type="tel"
          placeholder="Phone Number"
        />
      </Box>

      <Box pad={{ vertical: "small" }}>
        <Field
          component={FieldInputWithErrors}
          inputComponent={RadioButtonGroup}
          name="allows_sms_contact"
          label={
            <Box>
              <Text>Can we text you occasionally?</Text>
              <Text margin={{ top: "-1em" }} size="xsmall">
                {" "}
                You’ll also be able to order via text
              </Text>
              <Paragraph
                size="xsmall"
                style={{ lineHeight: "1.4em", fontWeight: 400 }}
                margin={{ bottom: "small" }}
              >
                By selecting "Yes", you agree to receive recurring automated
                promotional and personalized marketing text messages (e.g. cart
                reminders) from Backdrop at the cell number used when signing
                up. Consent is not a condition of any purchase. Reply HELP for
                help and STOP to cancel. Msg frequency varies. Msg & data rates
                may apply. View{" "}
                <Anchor
                  href="https://www.backdrophome.com/pages/messaging-terms-of-service/"
                  target="_blank"
                >
                  Terms
                </Anchor>{" "}
                &{" "}
                <Anchor
                  href="https://www.backdrophome.com/pages/privacy-policy/"
                  target="_blank"
                >
                  Privacy
                </Anchor>
                .
              </Paragraph>
            </Box>
          }
          options={[
            { label: "Yes", value: "true" },
            { label: "No", value: "false" },
          ]}
        />
      </Box>

      <Box direction="row-responsive" gap="small" justify="stretch">
        <Field
          component={FieldInputWithErrors}
          name="company_name"
          placeholder="Company Name*"
          autocapitalize={true}
          note="*Business name must match your Resale Certificate and qualifying documents"
        />
        <Field
          component={FieldInputWithErrors}
          name="company_website"
          placeholder="Company Website"
        />
      </Box>

      <Box direction="row-responsive" gap="small" justify="stretch">
        <Field
          component={FieldInputWithErrors}
          inputComponent={InputAddressAutocomplete}
          name="business_address1"
          onSelect={handleGoogleAddress}
          placeholder="Shipping Address"
        />
        <Field
          component={FieldInputWithErrors}
          name="business_address2"
          placeholder="apt, suite, floor, etc"
        />
      </Box>

      <Box direction="row-responsive" gap="small" justify="stretch">
        <Field
          component={FieldInputWithErrors}
          name="business_city"
          placeholder="City"
        />
        <Field
          component={FieldInputWithErrors}
          name="business_state"
          placeholder="State/Province"
          onChange={selected =>
            formikProps.setFieldValue("business_state", selected.target.value)
          }
        />
      </Box>
      <Box direction="row-responsive" gap="small" justify="stretch">
        <Field
          component={FieldInputWithErrors}
          name="business_zip"
          placeholder="Zip"
        />
        <Field
          component={FieldInputWithErrors}
          inputComponent={InputSelect}
          name="business_country"
          placeholder="Country"
          onChange={selected =>
            formikProps.setFieldValue("business_country", selected.target.value)
          }
          options={["United States", "Canada"]}
        />
      </Box>
      <Field type="hidden" name="industry_application_id" />
    </Box>
  )
}

export default FormSignupStepBasics
