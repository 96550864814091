import React, { useState } from "react"
import * as Yup from "yup"
import { useMutation } from "@apollo/react-hooks"
import { Formik, Field } from "formik"
import { keys } from "lodash/fp"
import { Box, Button, Text } from "grommet"
import FormSignupStepBasics from "./FormSignupStepBasics"
import FormSignupStepDetails from "./FormSignupStepDetails"

import CREATE_INDUSTRY_APPLICATION from "../queries/createIndustryApplication"

const getCountryValue = countryString => {
  switch (countryString) {
    case "United States":
      return "US"
    case "Canada":
      return "CA"
    default:
      return "US"
  }
}

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required Field*"),
  phone: Yup.string()
    .min(14, "Invalid Phone Number")
    .required("Required Field*"),
  company_name: Yup.string().required("Required Field*"),
  company_website: Yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    "Please enter a valid URL"
  ),
  business_address1: Yup.string()
    .trim()
    .required("Required Field*"),
  business_name: Yup.string(),
  business_city: Yup.string().required("Required Field*"),
  business_state: Yup.string().required("Required Field*"),
  business_zip: Yup.string().required("Required Field*"),
  business_country: Yup.string().required("Required Field*"),
  social_handle: Yup.string(),
  industry_type: Yup.string().required("Required Field*"),
  referred_by_email: Yup.string().email("Invalid email"),
  company_description: Yup.string(),
  annual_paint_budget: Yup.string().required("Required Field*"),
  other_primary_focus: Yup.string().when("primary_focus", {
    is: value => value.indexOf("Other") > -1,
    then: Yup.string().required("Required Field*"),
  }),
  member_group: Yup.string(),
})

const FormSignup = ({
  handleChange,
  handleBlur,
  onChange,
  errors,
  touched,
  isValid,
  values,
  referral_fetched,
  referred_by_member,
  referred_by_name,
  referred_by_email,
  member_group,
}) => {
  const [apiErrors, setApiErrors] = useState([])
  const [createIndustryApplication] = useMutation(CREATE_INDUSTRY_APPLICATION)

  const getInitalValue = key => {
    let defaultValue = ""
    switch (key) {
      case "referred_by_member":
        defaultValue = referred_by_member || undefined
        break
      case "referred_by_name":
        defaultValue = referred_by_name || ""
        break
      case "referred_by_email":
        defaultValue = referred_by_email || ""
        break
      case "primary_focus":
        defaultValue = []
        break
      case "documents":
        defaultValue = []
        break
      case "allows_sms_contact":
        defaultValue = "false"
        break
      case "member_group":
        defaultValue = member_group || undefined
        break
      case "business_country":
        defaultValue = "United States"
        break
      default:
        defaultValue = ""
    }
    return defaultValue
  }

  return (
    <Formik
      initialValues={{
        first_name: getInitalValue("first_name"),
        last_name: getInitalValue("last_name"),
        email: getInitalValue("email"),
        phone: getInitalValue("phone"),
        company_name: getInitalValue("company_name"),
        company_website: getInitalValue("company_website"),
        business_address1: getInitalValue("business_address1"),
        business_address2: getInitalValue("business_address2"),
        business_name: getInitalValue("business_name"),
        business_city: getInitalValue("business_city"),
        business_state: getInitalValue("business_state"),
        business_country: getInitalValue("business_country"),
        business_zip: getInitalValue("business_zip"),
        allows_sms_contact: getInitalValue("allows_sms_contact"),
        social_handle: getInitalValue("social_handle"),
        industry_type: getInitalValue("industry_type"),
        company_description: getInitalValue("company_description"),
        referred_by_member: getInitalValue("referred_by_member"),
        referred_by_name: getInitalValue("referred_by_name"),
        referred_by_email: getInitalValue("referred_by_email"),
        primary_focus: getInitalValue("primary_focus"),
        other_primary_focus: getInitalValue("other_primary_focus"),
        annual_paint_budget: getInitalValue("annual_paint_budget"),
        documents: getInitalValue("documents"),
        member_group: getInitalValue("member_group"),
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, setErrors, setTouched }) => {
        setSubmitting(true)
        setApiErrors([])

        const parsedValues = Object.assign({}, values, {
          business_country: getCountryValue(values.business_country),
          phone: values.phone.replace(/\D/g, ""),
          allows_sms_contact: values.allows_sms_contact === "true",
          primary_focus: [
            ...values.primary_focus,
            values.other_primary_focus ? values.other_primary_focus : "",
          ].join(", "),
        })

        try {
          const result = await createIndustryApplication({
            variables: parsedValues,
          })
          window.location.href = `/thanks?pro=${result.data.applyForIndustry.pro.id}`
        } catch (e) {
          console.log(e)
          console.log(String(e))
          setApiErrors([
            { message: String(e).replace("Error: GraphQL error: ", "") },
          ])
          setSubmitting(false)
        }
      }}
      displayName="Signup"
    >
      {formikProps => {
        return (
          <form onSubmit={formikProps.handleSubmit}>
            <FormSignupStepBasics
              formikProps={formikProps}
              referral_fetched={referral_fetched}
            />
            <FormSignupStepDetails
              formikProps={formikProps}
              referral_fetched={referral_fetched}
            />
            <Box gap="small" margin={{ bottom: "medium", top: "small" }}>
              {apiErrors.length > 0 &&
                apiErrors.map((e, i) => (
                  <div key={i}>
                    <Text color="status-critical">{e.message}</Text>
                  </div>
                ))}
              <Box direction="row-responsive" margin={{ vertical: "medium" }}>
                <Button
                  disabled={
                    !formikProps.isValid ||
                    keys(formikProps.touched).length === 0 ||
                    (formikProps.status && formikProps.status.uploading) ||
                    formikProps.isSubmitting
                  }
                  label="Apply"
                  type="submit"
                  primary
                  style={{ minWidth: "200px" }}
                />
              </Box>
              <Field type="hidden" name="referred_by_member" />
              <Field type="hidden" name="member_group" />
            </Box>
          </form>
        )
      }}
    </Formik>
  )
}

export default FormSignup
