import gql from "graphql-tag"

const MEMBER_BY_CODE = gql`
  query MemberByCode($code: String!) {
    memberByCode(code: $code) {
      member {
        id
        email
        name
      }
    }
  }
`

export default MEMBER_BY_CODE
