import { Box } from "grommet"

import styled from "styled-components"

const ConstrainedContainer = styled(Box)`
  margin: 0 auto;
  width: 100%;
  max-width: 700px;
`
export default ConstrainedContainer
