import React from "react"
import styled from "styled-components"

const StyledSelect = styled.select`
  background: #fff;
  border: none;
  border-radius: 0;
  font-size: ${p => p.theme.text.medium.size};
  -webkit-appearance: none; /* WebKit/Chromium */
  -moz-appearance: none;
  cursor: pointer;
  font-weight: ${p => (p.field.value === "" ? "normal" : 500)};
  color: ${p =>
    p.field.value === "" ? p.theme.global.colors["dark-4"] : "#000"};
  font-family: akzidenz-grotesk, sans-serif;
  line-height: 71px;
  padding: 0 14px;

  background-image: url(//cdn.shopify.com/s/files/1/0051/6295/8946/t/2/assets/icon-arrow-down.svg?26772);
  background-position: right 14px center;
  background-repeat: no-repeat;
  background-size: 20px;
  margin-block-end: initial;
  margin-block-start: initial;
  margin-inline-end: initial;
  margin-inline-start: initial;
`

const InputSelect = ({ options, ...rest }) => (
  <StyledSelect {...rest}>
    <option disabled value="">
      {rest.placeholder}
    </option>
    {options.map(x => (
      <option key={x} value={x}>
        {x}
      </option>
    ))}
  </StyledSelect>
)

export default InputSelect
