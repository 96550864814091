import { find, includes, get } from "lodash/fp"

function getComponentType(type, components, property = "short_name") {
  return get(property, find(x => includes(type, get("types", x)), components))
}

const parseGoogleAddressComponents = (components, prefix = "") => {
  const output = {}
  output[`${prefix}address1`] = `${getComponentType(
    "street_number",
    components
  )} ${getComponentType("route", components)}`
  output[`${prefix}city`] = getComponentType(
    "locality",
    components,
    "long_name"
  )
  output[`${prefix}state`] = getComponentType(
    "administrative_area_level_1",
    components
  )
  output[`${prefix}zip`] = getComponentType("postal_code", components)
  return output
}

export default parseGoogleAddressComponents
