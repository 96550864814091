import React from "react"
import { isNil, get, head } from "lodash/fp"
import { useQuery } from "@apollo/react-hooks"
import Heading from "../components/Heading"
import ResponsiveInnerPad from "../components/ResponsiveInnerPad"
import { Box } from "grommet"
import FormSignup from "../components/FormSignup"
import ConstrainedContainer from "../components/ConstrainedContainer"

import MEMBER_BY_CODE from "../queries/memberByCode"
import MEMBER_GROUPS_BY_NAME from "../queries/memberGroupsByName"

const Signup = ({ code, memberGroup, utmSource }) => {
  const { loading: memberLoading, error, data } = useQuery(MEMBER_BY_CODE, {
    variables: { code },
    skip: !code,
  })

  const { loading: groupLoading, data: groupData } = useQuery(
    MEMBER_GROUPS_BY_NAME,
    {
      variables: { name: memberGroup || utmSource },
      skip:
        (!utmSource && !memberGroup) || !Number.isNaN(parseInt(memberGroup)),
    }
  )

  const loading = memberLoading || groupLoading
  const memberGroupId = Number.isNaN(parseInt(memberGroup))
    ? get("id", head(get("memberGroups", groupData)))
    : memberGroup

  return (
    <ConstrainedContainer>
      <ResponsiveInnerPad margin={{ top: "large" }}>
        <Heading level={1} size="medium" margin={{ top: "xlarge" }}>
          Industry By Backdrop
        </Heading>
        <Box margin={{ vertical: "medium" }}>
          {loading ? (
            "Please wait..."
          ) : (
            <FormSignup
              referred_by_member={get("memberByCode.member.id", data)}
              referred_by_name={get("memberByCode.member.name", data)}
              referred_by_email={get("memberByCode.member.email", data)}
              referral_fetched={!isNil(get("memberByCode.member", data))}
              member_group={memberGroupId}
            />
          )}
        </Box>
      </ResponsiveInnerPad>
    </ConstrainedContainer>
  )
}

export default Signup
