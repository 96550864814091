import gql from "graphql-tag"

const CREATE_INDUSTRY_APPLICATION = gql`
  mutation CreateIndustryApplication(
    $first_name: String!
    $last_name: String!
    $email: String!
    $phone: String!
    $business_address1: String!
    $business_address2: String
    $business_name: String
    $business_city: String!
    $business_state: String!
    $business_zip: String!
    $business_country: ENUM_PRO_BUSINESS_COUNTRY!
    $company_name: String!
    $company_website: String!
    $industry_type: String
    $company_description: String
    $referred_by: ID
    $referred_by_name: String
    $referred_by_email: String
    $referred_by_member: ID
    $annual_paint_budget: String
    $primary_focus: String
    $allows_sms_contact: Boolean
    $documents: JSON
    $social_handle: String
    $member_group: ID
  ) {
    applyForIndustry(
      input: {
        data: {
          first_name: $first_name
          last_name: $last_name
          email: $email
          phone: $phone
          company_name: $company_name
          company_website: $company_website
          business_address1: $business_address1
          business_address2: $business_address2
          business_name: $business_name
          business_city: $business_city
          business_state: $business_state
          business_zip: $business_zip
          business_country: $business_country
          industry_type: $industry_type
          referred_by_name: $referred_by_name
          referred_by_email: $referred_by_email
          referred_by: $referred_by
          referred_by_member: $referred_by_member
          company_description: $company_description
          annual_paint_budget: $annual_paint_budget
          primary_focus: $primary_focus
          allows_sms_contact: $allows_sms_contact
          documents: $documents
          social_handle: $social_handle
          member_group: $member_group
        }
      }
    ) {
      pro {
        id
        customer_id
        first_name
        last_name
        email
        phone
        company_name
        company_website
        business_address1
        business_address2
        business_city
        business_state
        business_zip
        stripe_connect_url
      }
      jwt
    }
  }
`

export default CREATE_INDUSTRY_APPLICATION
