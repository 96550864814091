import gql from "graphql-tag"

const MEMBER_GROUPS_BY_NAME = gql`
  query MemberGroups($name: String!) {
    memberGroups(where: { name: $name }) {
      id
      name
    }
  }
`

export default MEMBER_GROUPS_BY_NAME
